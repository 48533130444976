import type { FC } from 'react'
import Head from 'next/head'
import { Currency } from '@model/product/PriceInfo'

export interface HelmetProps {
  title: string
  description?: string
  type: 'product' | 'category' | 'collection' | 'website'
  imageUrl?: string
  url?: string
  locale?: string
  price?: string
  currency?: Currency
  brand?: string
  stock?: string
  condition?: string
  sku?: string
}

const defaultImage = 'https://shopping.tallink.com/v1/page-image/et?store=store2'

const Helmet: FC<HelmetProps> = (props) => {
  const { title, description, type, imageUrl, url, locale, price, currency, brand, stock, condition, sku } = props

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content={type} />
      <meta
        name="keywords"
        content="Sõbrapäev, Kuupakkumine, Ilu, Mood, Lapsed, Naistele, Meestele, Lastele, Maiustused, Kodu ja elektroonika, Kodu, Tervis, Joogid, Viimane võimalus,
Trendikalt sügisesse, Eesti 30, Lihavõtted, Kevad, Päikese kampaania, Tubakas, Kingikampaaniad"
      />
      {imageUrl ? <meta property="og:image" content={imageUrl} /> : <meta property="og:image" content={defaultImage} />}

      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:locale" content={locale} />
      <meta property="og:price:amount" content={price} />
      <meta property="og:price:currency" content={currency} />
      <meta property="product:brand" content={brand} />
      <meta property="product:availability" content={stock} />
      <meta property="product:condition" content={condition} />
      <meta property="product:locale" content={locale} />
      <meta property="product:price:amount" content={price} />
      <meta property="product:price:currency" content={currency} />
      <meta property="product:retailer_item_id" content={sku} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@TallinkSilja" />
    </Head>
  )
}

export default Helmet
